import gql from 'graphql-tag'

const CreateTenantMutation = gql`
mutation CreateTenantMutation($input: CreateTenantMutationInput!){
    createTenant(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CreateFinancialAccount = gql`
mutation CreateFinancialAccount($input: CreateFinancialAccountInput!){
    createFinancialAccount(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CreateBranchMutation = gql`mutation CreateBranchMutation($input: CreateBranchMutationInput!){
    createBranch(input:$input){
        ok
        branchNode{
            id
        }
        errors{
            messages
        }
    }
} `

const CreateMember = gql`
mutation CreateMember($input: CreateMemberMutationInput!){
    createMember(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const CreateUserMutation = gql`
mutation CreateUser($input: CreateUserMutationInput!){
    createUser(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateUser = gql`
mutation UpdateUser($input: UpdateUserMutationInput!){
    updateUser(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateMember = gql`
mutation UpdateMember($input: UpdateMemberMutationInput!){
    updateMember(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const BulkCreateUpdateMember = gql`
mutation BulkCreateUpdateMember($input: BulkCreateUpdateMemberMutationInput!){
    bulkCreateUpdateMember(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const BulkUpdateUser = gql`
mutation BulkUpdateUser($input: BulkUpdateUserMutationInput!){
    bulkUpdateUser(input:$input){
        ok
        errors{
            messages
        }
    }
} `

const UpdateDriver = gql`
mutation updateDriverMutation($input: UpdateDriverMutationInput!){
    updateDriver(input:$input){
        ok
        errors{
            messages
        }
    }
} `

export { CreateBranchMutation, CreateMember, UpdateMember, BulkCreateUpdateMember, BulkUpdateUser, UpdateDriver, CreateUserMutation, UpdateUser, CreateTenantMutation, CreateFinancialAccount }
