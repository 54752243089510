import React from "react"

const PreferredDateTimeContext = React.createContext({
    preferredDateTimeInfo: {
        dateFormat: "MMM D, YYYY",
        timeFormat: "h:mm A",
        timezone: "America/New_York"
    },
    setPreferredDateTimeInfo: () => { }
})


const PreferredDateTimeProvider = (props) => {
    return (
        <PreferredDateTimeContext.Provider value={{ preferredDateTimeInfo: props.preferredDateTimeInfo, setPreferredDateTimeInfo: props.setPreferredDateTimeInfo }}>
            {props.children}
        </PreferredDateTimeContext.Provider>
    )
}

export { PreferredDateTimeContext, PreferredDateTimeProvider }