import React, { Component } from 'react'
import { Container, Row, Col, Input } from 'reactstrap'
import FinancialAccountList from "./FinancialAccountList"
import './FinancialAccountListContainer.css'
import NewFinancialAccountModal from "./NewFinancialAccountModal"
import RestrictedSection from "../../Components/RestrictedSection"

class FinancialAccountListContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchTerm: null,
            orderBy: "-id"
        }
    }

    updateInput = (e) => {
        let name = e.target.name
        let value = e.target.value
        if (value === "null") {
            value = null
        }
        // To change string inputs to boolean because only strings can be passed to html options
        if (e.target.dataset.type) {
            if (value === "false") {
                value = false
            } else {
                value = true
            }
        }

        this.setState({ [name]: value })
    };

    updateOrderBy = (name) => {
        name = name && name.includes(".") ? name.replace(".", "__") : name
        this.setState({ orderBy: this.state.orderBy === name ? "-" + name : name })
    }

    toggleModal = (modalName) => {
        if (this.state.openModal !== modalName) {
            this.setState({ openModal: modalName })
        } else {
            this.setState({ openModal: "" })
        }
    }

    render() {
        return (
            <RestrictedSection requiredPermission="view_financialaccount">
                <Container fluid className="bos-listing-container" >
                    <Row>
                        <Col xs={4}>
                            <span className="driver-search-filter">
                                <Input type="text" name="searchTerm" placeholder="Search" onChange={this.updateInput} />
                                <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                            </span>
                        </Col>
                        <Col xs={8} className="text-right mt-2">
                            <a id="newFinancialAccount" className="driver-list-button" onClick={() => this.toggleModal("NewFinancialAccount")}>
                                New Financial Account | <i className="fa fa-plus" aria-hidden="true"></i>
                            </a>
                            {this.state.openModal === "NewFinancialAccount" && <NewFinancialAccountModal handleClose={() => this.toggleModal("")} open={this.state.openModal === "NewFinancialAccount"} />}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <FinancialAccountList currentUser={this.props.currentUser} searchTerm={this.state.searchTerm} orderBy={this.state.orderBy} updateOrderBy={this.updateOrderBy} />
                        </Col>
                    </Row>
                </Container>
            </RestrictedSection>
        )
    }
}
export default FinancialAccountListContainer
