import React, { Component } from 'react'
import { compose } from 'react-apollo'
import gql from 'graphql-tag'
import { withApollo } from 'react-apollo/index'
import { Col, Row, Input, InputGroup, InputGroupAddon, InputGroupText, Popover, PopoverHeader, PopoverBody, UncontrolledTooltip } from 'reactstrap'
import moment from "moment"
import { DateRange } from "react-date-range"
import { debounce } from 'lodash'
import MassMessageModal from "../../Material/MassMessageModal"
import ListingFilters from "../../Material/ListingFilters"
import renderEnumToInt from "../../Functions/renderEnumToInt"
import toTitleCase from "../../Functions/toTitleCase"
import updateFilters from "../../Functions/updateFilters"
import getFilterValuesFromQueryString from "../../Functions/getFilterValuesFromQueryString"
import handleConfigurationChange from "../../Functions/handleConfigurationChange"
import Select from "react-select"

const returnStages = [
    { value: -1, label: "Unknown" }, { value: 0, label: "Cancelled" },
    { value: 1, label: "Future Returns" }, { value: 2, label: "Confirmed" },
    { value: 3, label: "Awaiting Closeout" }, { value: 4, label: "Returned" },
]
let driverStages = [
    { value: [6], label: "Active" },
    { value: [10], label: "Inactive", },
    { value: [1], label: "Applied" },
    { value: [0], label: "Didnt Take Car" },
]
const AllAgreementTypes = gql`{
    optionsList:allAgreementTypes{
      edges{
        node{
            id
            name
        }
      }
    }
}`

const AllReturnType = gql`query AllReturnType{
    optionsList:  __type(name:"CarReturnReturnType"){
        states: enumValues{
            name
            description
        }
    }
}`

const AllPickupType = gql`query AllReturnType{
    optionsList:__type(name: "AgreementPickupType") {
        states: enumValues {
            name
            description
        }
    }
}`




const AllReturnCategories = gql`query AllReturnCategories{
    optionsList: __type(name:"CarReturnReturnCategory"){
        states: enumValues{
        name
        description
        }
    }
}`

const AgreementsStageQuery = gql`query AgreementsStageQuery{
    optionsList: __type(name: "AgreementStage") {
        states: enumValues {
            name
            description
        }
    }
}`

const AllCarLocations = gql`{
    optionsList: allCarLocations(isPickupLocation:true){
      edges{
        node{
          name
          id
          branch{
            id
          }
        }
      }
    }
}`
const AllCarModels = gql`
  {
    optionsList: allCarModels(orderBy: ["-make"]){
      edges {
        node {
          id
          name
          make
          series
          groupType{
            id
            name
          }
        }
      }
    }
  }
`

const AllPromoNames = gql`
{
    optionsList: allPromotionsTemplateNames
}`

const dateFilterTypes = ["Scheduled Date", "Rental End Date", "Date Added"]

class RentalsListHeader extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            configurations: null
        }
    }
    getFilterConfigurations = (activeTab) => {
        return [
            { type: "select", name: "stage", title: "Rental Stage", optionsQuery: AgreementsStageQuery, placeholder: "Filter By Rental Stage", valueSelector: "name", extractValue: true, labelSelector: "description", showFilter: activeTab === "All Rentals" },
            { type: "select", name: "returnType", title: "Return Type", optionsQuery: AllReturnType, placeholder: "Filter By Return Type", valueSelector: "description", labelSelector: "description", objectName: "state", showFilter: activeTab === "All Returns" },
            { type: "select", name: "returnStage", title: "Return Stage", options: returnStages, placeholder: "Filter By Return Stage", isMulti: true, showFilter: activeTab === "All Returns" },
            {
                type: "boolean", name: "contractSigned", title: "Contract Signed", optionOne: "YES",
                optionTwo: "NO", showFilter: ["All Rentals"].includes(activeTab)
            },
            { type: "boolean", name: "hasPromo", title: "Rental With Promo", optionOne: "YES", optionTwo: "NO", placeholder: "Filter By Rental With Promo", showFilter: true, showFilter: ["All Rentals"].includes(activeTab) },
            { type: "select", name: "promoNames", title: "Promo Names", placeholder: "Filter By Promo Name", optionsQuery: AllPromoNames, showFilter: true, showFilter: ["All Rentals"].includes(activeTab), isMulti: true },
            { type: "select", name: "returnCategories", title: "Return Categories", optionsQuery: AllReturnCategories, valueSelector: "description", labelSelector: "description", isMulti: true, placeholder: "Filter By Return Categories", showFilter: ["All Returns"].includes(activeTab) },
            { type: "select", name: "pickupTypes", title: "Pickup Types", optionsQuery: AllPickupType, valueSelector: "description", labelSelector: "description", isMulti: true, placeholder: "Filter By Pickup Type", showFilter: ["All Rentals"].includes(activeTab) },
            { type: "select", name: "driverStage", title: "DrdateFilterTypesiver Stage", options: driverStages, placeholder: "Filter By Stage", showFilter: ["All Returns"].includes(activeTab) },
            { type: "select", name: "agreementTypes", title: "Agreement Types", optionsQuery: AllAgreementTypes, valueSelector: "id", labelSelector: "name", isMulti: true, placeholder: "Filter By Agreement Types", showFilter: ["All Returns", "All Rentals"].includes(activeTab) },
            { type: "select", name: "pikcupLocations", title: "Pickup Locations", optionsQuery: AllCarLocations, valueSelector: "id", labelSelector: "name", isMulti: true, placeholder: "Filter By Pickup Locations", showFilter: activeTab === "All Rentals", isMulti: true },
            { type: "select", name: "carModelNames", title: "Car Model Name", optionsQuery: AllCarModels, placeholder: "Filter By Car Model Name", valueSelector: "id", labelSelector: "name", isMulti: true, showFilter: activeTab === "All Rentals" },
        ]
    }
    toggleModal = (modalName) => {
        this.setState({ openModal: this.state.openModal === modalName ? "" : modalName })
    }
    updateSearchTerm = (searchTerm) => {
        updateFilters({ ...this.props.filterValues, searchTerm }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
    }
    removeFilter = (filterName) => {
        let filterValues = this.props.filterValues
        let filterKeys = Object.keys(filterValues)
        if (filterKeys.includes(filterName)) {
            delete filterValues[filterName]
            updateFilters({ ...this.props.filterValues }, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues)
        }
    }
    getValueByFilterType = (filterConfigs, value) => {
        if (filterConfigs.type == "select") {
            let options = filterConfigs["options"]
            if (options && options.length > 0) {
                if (Array.isArray(value)) {
                    value = value.map(item => options.find(option => option.value == item) && options.find(option => option.value == item).label)
                } else {
                    if (value === true) {
                        value = 'true'
                    }
                    else if (value === false) {
                        value = 'false'
                    }
                    value = options.find(option => option.value == value) && options.find(option => option.value == value).label
                }
            }
        } else if (filterConfigs.type === "boolean")
            value = toTitleCase(value.toString())
        return Array.isArray(value) ? value.join(", ") : value
    }
    setConfigurations = (activeTab) => {
        let conf = this.getFilterConfigurations(this.props.activeTab)
        let configurations = conf.filter(filter => filter['showFilter']).map(filter => {
            if (filter['optionsQuery']) {
                let options = []
                this.props.client.query({
                    query: filter.optionsQuery,
                    variables: { ...filter.variables }
                }).then(result => {
                    if (result && result.data.optionsList && result.data.optionsList.edges && result.data.optionsList.edges.length > 0) {
                        if (filter['name'] === "carModelNames") {
                            options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node.name, label: toTitleCase(options.node.make) + " " + options.node.name })).filter((obj, index, arr) => arr.findIndex(model => model.value === obj.value) === index)
                        } else {
                            options = result.data.optionsList.edges.map(options => options.node && ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }))
                        }
                    } else if (filter['name'] === "promoNames") {
                        options = result.data.optionsList.map(option => ({ value: option, label: option }))
                    }
                    else if (result && result.data.optionsList && result.data.optionsList.states && result.data.optionsList.states.length > 0) {
                        options = result.data.optionsList.states.map(options => options.node ? ({ value: options.node[filter.valueSelector], label: options.node[filter.labelSelector] }) : ({ value: filter.extractValue ? renderEnumToInt(options[filter.valueSelector]) : options[filter.valueSelector], label: options[filter.labelSelector] }))
                    }
                    filter['options'] = options
                    delete filter['optionsQuery']
                })
            }
            return filter
        })
        this.setState({ configurations: configurations })
    }
    componentDidMount() {
        if (this.props.activeTab)
            this.setConfigurations()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeTab !== this.props.activeTab) {
            this.setConfigurations()
        }

        if (this.state.configurations !== prevState.configurations) {
            let urlFilterValues = getFilterValuesFromQueryString(this.props.location.search, this.state.configurations)
            handleConfigurationChange(prevState.configurations, this.state.configurations, urlFilterValues, this.props.setFilterValues, this.props.history, this.props.location, this.props.defaultFilters, updateFilters)
        }
    }
    applyDateFitler = () => {
        this.setState({ showDateRangePopup: true })
        let startDate = this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate ? this.props.filterValues.selectionRange[0].startDate : new Date()
        let endDate = this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].endDate ? this.props.filterValues.selectionRange[0].endDate : null
        this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [{ startDate, endDate, key: 'selection' }] })
    }
    clearDateFilter = () => {
        let filterValues = this.props.filterValues
        filterValues = delete filterValues['selectionRange']
        this.props.setFilterValues({ ...this.props.filterValues })
    }
    updateDateFilterType = (dateFilterType) => {
        let { filterValues } = this.props
        if (dateFilterType === "Rental End Date") {
            filterValues["returnStage"] = [4]
        }
        else if (filterValues["returnStage"] && filterValues["returnStage"].includes(4)) {
            delete filterValues["returnStage"]
        }
        this.props.setFilterValues({ ...filterValues })
        this.props.updateDateFilterType(dateFilterType)
    }

    render() {
        return (
            <Row className="pb-2">
                {this.state.openModal === "viewFilters" &&
                    <ListingFilters open={this.state.openModal === "viewFilters"} handleClose={() => this.toggleModal("")}
                        target="viewFilters" filterValues={this.props.filterValues} setFilterValues={(filters => updateFilters(filters, this.state.configurations, this.props.history, this.props.location, this.props.setFilterValues))}
                        filters={this.state.configurations}
                    />}
                {this.state.openModal === "MassMessageModal" && <MassMessageModal handleClose={() => this.toggleModal("MassMessageModal")} open={this.state.openModal === "MassMessageModal"} drivers={this.props.selectedDrivers && this.props.selectedDrivers.length > 0 ? this.props.selectedDrivers : null} />}
                <Col xs={4}>
                    <span className="driver-search-filter">
                        <Input type="text" name="searchTerm" placeholder="Search" onChange={(e) => this.updateSearchTerm(e.target.value)} />
                        <i className="fa fa-lg fa-search search-icon" aria-hidden="true"></i>
                    </span>
                </Col>
                {["All Returns", "All Rentals"].includes(this.props.activeTab) && <Col xs={3}>
                    <InputGroup className="mb-0 date-range-group">
                        <InputGroupAddon addonType="prepend">
                            <a id="customDateSelection" onClick={this.applyDateFitler}><InputGroupText className="h-100"><i className="fa fa-calendar" aria-hidden="true"></i></InputGroupText></a>
                        </InputGroupAddon>
                        <Input disabled placeholder="Select Custom Date Range" value={this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate ? moment(this.props.filterValues.selectionRange[0].startDate).tz("America/New_York").format("ddd, MMM D YYYY") + " - " + moment(this.props.filterValues.selectionRange[0].endDate).tz("America/New_York").format("ddd, MMM D YYYY") : ""} />
                        {this.props.filterValues && this.props.filterValues.selectionRange && this.props.filterValues.selectionRange[0].startDate && this.props.filterValues.selectionRange[0].endDate && <i className="fa fa-times-circle-o reset-date-range" onClick={() => this.clearDateFilter()} aria-hidden="true"></i>}
                        <Popover placement="bottom" isOpen={this.state.showDateRangePopup} target="customDateSelection" toggle={() => this.setState({ showDateRangePopup: false })}>
                            <PopoverHeader className="text-center">Select Date Range</PopoverHeader>
                            <PopoverBody>
                                {this.props.activeTab === "All Returns" &&
                                    <>
                                        <Select
                                            key="dateFilterType"
                                            className="bos-custom-select"
                                            classNamePrefix="bos-select"
                                            options={dateFilterTypes.map(item => ({ value: item, label: "Filter By " + item }))}
                                            defaultValue={dateFilterTypes.filter(value => value == this.props.dateFilterType).map(item => ({ value: item, label: "Filter By " + item }))}
                                            onChange={item => this.updateDateFilterType(item.value)}
                                        />
                                        <br />
                                    </>
                                }
                                <DateRange key="dateRange" ranges={this.props.filterValues && this.props.filterValues.selectionRange} onChange={(ranges) => this.props.setFilterValues({ ...this.props.filterValues, selectionRange: [ranges.selection] })} editableDateInputs={true} moveRangeOnFirstSelection={true} />
                            </PopoverBody>
                        </Popover>
                    </InputGroup>
                </Col>}
                <Col xs={["All Returns", "All Rentals"].includes(this.props.activeTab) ? 5 : 8} className="text-right mt-2">
                    <a className="driver-list-button" onClick={() => this.toggleModal("MassMessageModal")}>
                        Message Drivers | <i className="fa fa-commenting-o" aria-hidden="true"></i>
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {["All Returns", "All Rentals"].includes(this.props.activeTab) && <a id="viewFilters" className="driver-list-button" onClick={() => this.toggleModal("viewFilters")}>
                        Apply Filters | <i className="fa fa-filter" aria-hidden="true"></i> {this.props.filterValues && Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).length || ""}
                    </a>}
                </Col>
                <Col xs={12} className="mt-2">
                    {Object.keys(this.props.filterValues).filter(key => this.props.filterValues[key] !== null && this.state.configurations && this.state.configurations.find(setting => setting.name == key) && this.state.configurations.find(setting => setting.name == key).showFilter).map(key =>
                        <span className="search-filter-preview">
                            <span>
                                <i className="fa fa-times-circle" onClick={() => this.removeFilter(this.state.configurations.find(setting => setting.name == key).name)}></i>&nbsp;&nbsp;
                                {this.state.configurations.find(setting => setting.name == key).title}
                            </span>
                            {this.getValueByFilterType(this.state.configurations.find(setting => setting.name == key), this.props.filterValues[key])}
                        </span>
                    )}
                    {this.props.selectedDrivers && this.props.selectedDrivers.length > 0 && <span className="search-filter-preview">
                        <span> <i className="fa fa-times-circle" onClick={this.props.resetSelectedDrivers}></i> &nbsp;&nbsp; Selected Drivers</span>
                        {this.props.selectedDrivers.length}
                    </span>}
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
)(RentalsListHeader)
