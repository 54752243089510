import gql from "graphql-tag"

const DriverDocumentsQuery = gql`query DriverDocumentsQuery($cursor: String, $first: Int, $orderBy: [String], $id: ID, $categoriesIn: [ID], $typesIn: [ID], $isDeleted:Boolean) {
    documents:allDriverDocuments(first:$first, orderBy:$orderBy, after: $cursor, driverId: $id, categoriesIn:$categoriesIn, typesIn:$typesIn, isDeleted:$isDeleted){
      edges{
        cursor
        node{
            id
            pk
            dateAdded
            name
            isVerified
            history {
              date
              user
              comment
            }
            documentType {
                id
                typeName
                allowMultiple
            }
            documentUrl
            documentSlug
            isArchived

            expiration
            isExpired
            metadata
        }
    }
    pageInfo{
        endCursor
        hasNextPage
    }
}}`


const CarDocumentsQuery = gql`query CarDocumentsQuery($cursor: String, $first: Int!, $orderBy: [String], $id: ID!, $categoriesIn: [ID], $typesIn: [ID], $isDeleted:Boolean) {
    documents:allCarDocuments(first:$first, orderBy:$orderBy, after: $cursor, car_Id:$id, categoriesIn:$categoriesIn, typesIn:$typesIn, isDeleted:$isDeleted){
      edges{
        cursor
        node{
            id
            pk
            dateAdded
            name
            history {
              date
              user
              comment
            }
            documentType {
                id
                typeName
                documentCategory {
                    id
                    name
                  }
            }
            documentUrl
            documentSlug
            isArchived
            expiration
            isExpired
            metadata
        }
    }
    pageInfo{
        endCursor
        hasNextPage
    }
}}`


const AllCarDocumentTypesQuery = gql`query AllCarDocumentTypesQuery{
    allCarDocumentTypes(isActive:true){
      edges {
        node {
            id
            typeName
            documentCategory {
                id
                name
                description:ddescription
            }
            requiresExpiration
            allowMultiple
            visibleInDriverApp
        }
      }
    }
}`

const AllDriverDocumentTypesQuery = gql`query AllDriverDocumentTypesQuery($driverId:ID){
    allDriverDocumentTypes(isActive:true, driverId:$driverId){
      edges {
        node {
            id
            typeName
            documentCategory {
                id
                name
                description:ddescription
            }
            allowMultiple
            visibleInDriverApp
        }
      }
    }
}`

const AllDocumentCategoryQuery = gql`query AllDocumentCategoryQuery{
    allDocumentCategory{
      edges {
        node {
            id
            name
            description:ddescription
        }
      }
    }
}`


export { DriverDocumentsQuery, CarDocumentsQuery, AllCarDocumentTypesQuery, AllDriverDocumentTypesQuery, AllDocumentCategoryQuery }