import React, { Component } from 'react'
import { Input, Row, Col } from "reactstrap"
import moment from "moment"
import Select from "react-select"
import gql from 'graphql-tag'
import { compose, graphql, withApollo } from 'react-apollo'

const CurrentUser = gql`
query UserQuery{
    currentUser{
      id
      member {
        id
        preferredTimezone
      }
    }
}
`
const DEFAULT_TIMEZONE = "America/New_York"
class DateTimePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allTimezones: moment.tz.names().map(timezone => ({ value: timezone, label: moment.tz(timezone).format('zz') })),
            timezone: DEFAULT_TIMEZONE,
            datetime: moment(this.props.value).tz(DEFAULT_TIMEZONE).format(moment.HTML5_FMT.DATETIME_LOCAL),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.timezone !== prevState.timezone) {
            if (this.state.datetime === "" || !moment(this.state.datetime).isValid()) {
                this.props.setError("Enter valid date time value")
                return
            }
            let dateTimeTz = moment(this.state.datetime).tz(this.state.timezone, 'ddd DD-MMM-YYYY, hh:mm A')
            this.setState({ datetime: dateTimeTz.format(moment.HTML5_FMT.DATETIME_LOCAL) })
            this.props.updateInput(this.props.name, dateTimeTz.format())
        } else if (this.state.datetime !== prevState.datetime) {
            if (!moment(this.state.datetime).isValid()) {
                this.props.setError("Enter valid date time value")
                return
            }
            this.props.updateInput(this.props.name, moment(this.state.datetime).tz(this.state.timezone, 'ddd DD-MMM-YYYY, hh:mm A').format())
        }

        if (this.props.currentUser !== prevProps.currentUser && this.props.currentUser.member && this.props.currentUser.member.preferredTimezone) {
            this.setState({ timezone: this.props.currentUser.member.preferredTimezone, datetime: moment(this.props.value).tz(this.props.currentUser.member.preferredTimezone).format(moment.HTML5_FMT.DATETIME_LOCAL) })
        }
    }

    render() {
        return (
            <Row>
                <Col xs={8}>
                    <Input
                        type={this.props.type}
                        id={this.props.id}
                        name={this.props.name}
                        value={this.state.datetime}
                        min={this.props.min}
                        max={this.props.max}
                        onInput={(e) => this.setState({ datetime: e.target.value })}
                    />
                </Col>
                <Col xs={4}>
                    <Select
                        className="bos-custom-select" classNamePrefix="bos-select"
                        name="timezone" placeholder="Select Timezone"
                        options={this.state.allTimezones} isDisabled
                        value={this.state.allTimezones.find(item => item.value === this.state.timezone)}
                        onChange={(item) => this.setState({ timezone: item.value })}
                    />
                </Col>
            </Row>
        )
    }
}

export default compose(
    withApollo,
    graphql(CurrentUser, {
        options: {
            fetchPolicy: 'cache-and-network',
        },
        props({ data: { currentUser } }) { return { currentUser } },
    })
)(DateTimePicker)
