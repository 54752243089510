import React, { Component } from 'react'
import { compose, graphql } from "react-apollo"
import { withApollo } from "react-apollo/index"
import gql from 'graphql-tag'
import logo from './assets/images/logo.png'
import { ReactComponent as Promo } from './assets/images/icons/promo.svg'
import AttributeConfigurations from "./Attributes/AttributeConfigurations"
import { Link } from "react-router-dom"
import { Label } from "reactstrap"
import { HasPermissionsQuery } from "./Functions/querys"
import './App.css'

const MENU_ITEMS = [
    {
        parentName: "Tasks Management",
        items: [
            { name: "Boards", link: "/boards/tasks/", icon: "fa-columns", isVisible: true, permissionRequired: "view_taskrabbit" },
        ],
    },
    {
        parentName: "Drivers",
        items: [
            { name: "Lets Follow Up", link: "/customers/followup/", icon: "fa-bell-o", isVisible: true, permissionRequired: null },
            { name: "Leads", link: "/customers/leads/", icon: "fa-magnet", isVisible: true, permissionRequired: null },
            { name: "Applications", link: "/customers/applications/", icon: "fa-magnet", isVisible: true, permissionRequired: null },
            { name: "Insurances", link: "/customers/insurance/", icon: "fa-shield", isVisible: true, permissionRequired: null },
            { name: "Drivers", link: "/", icon: "fa-user", isVisible: true, permissionRequired: null },
            { name: "Collections", link: "/customers/collections/", icon: "fa-usd", isVisible: true, permissionRequired: null },
            { name: "Subscriptions", link: "/customers/subscriptions/", icon: "fa-clock-o", isVisible: true, permissionRequired: null },
            { name: "Interactions", link: "/customers/interaction", icon: "fa-exchange", isVisible: true, permissionRequired: null },
            { name: "Argyle", link: "/customers/argyle", icon: "fa fa-plug", isVisible: true, permissionRequired: null },
        ],
    },
    {
        parentName: "Cars",
        items: [
            { name: "Fleet Management", link: "/car/", icon: "fa-files-o", isVisible: true, permissionRequired: null },
            { name: "Buying & Selling", link: "/car/buying_selling/", icon: "fa-shopping-cart", isVisible: true, permissionRequired: "view_cardeal" },
            { name: "Available Cars", link: "/car/available/", icon: "fa-car", isVisible: true, permissionRequired: null },
            { name: "Pricing", link: "/car/pricing", icon: "fa-money", isVisible: true, permissionRequired: "view_pricingtemplate" },
            { name: "Promotions", link: "/car/promotions", customIcon: <Promo width={20} height={20} fill={"#fffff"} />, isVisible: true, permissionRequired: "view_promotiontemplate" },
            { name: "Registrations", link: "/car/registrations/", icon: "fa-registered", isVisible: false, permissionRequired: "view_carregistration" },
            { name: "Inspections", link: "/car/inspections/", icon: "fa-file-text-o", isVisible: false, permissionRequired: "view_carinspection" },
        ],
    },
    {
        parentName: "Reservations",
        items: [
            { name: "Reserved Cars", link: "/reservation/open/", icon: "fa-calendar-check-o", isVisible: true, permissionRequired: null },
            { name: "Awaiting Reservations", link: "/reservation/inactive/", icon: "fa-calendar-minus-o", isVisible: true, permissionRequired: null },
            { name: "All Reservations", link: "/reservation/", icon: "fa-calendar-o", isVisible: true, permissionRequired: null },
        ],
    },
    {
        parentName: "Rentals",
        items: [
            { name: "All Rentals", link: "/rental/", icon: "fa-road", isVisible: true, permissionRequired: null },
            { name: "Car Returns", link: "/carreturn/", icon: "fa-reply", isVisible: true, permissionRequired: null },
        ],
    },
    {
        parentName: "Maintenance",
        items: [
            { name: "Car Issues", link: "/maintenance/issues/", icon: "fa-cog", isVisible: true, permissionRequired: "view_carissues" },
            { name: "Maintenance Jobs", link: "/maintenance/jobs/", icon: "fa-cogs", isVisible: true, permissionRequired: "view_job" },
            { name: "Work Order", link: "/maintenance/work_order/", icon: "fa-cogs", isVisible: true, permissionRequired: "view_workorder" },
        ],
    },
    {
        parentName: "Organization",
        items: [
            { name: "Tenants", link: "/organization/tenants/", icon: "fa-sitemap", isVisible: true, permissionRequired: "view_tenant" },
            { name: "Branches", link: "/organization/branches/", icon: "fa-code-fork", isVisible: true, permissionRequired: "view_branch" },
            { name: "Members", link: "/organization/members/", icon: "fa-users", isVisible: true, permissionRequired: "view_member" },
            { name: "Permissions", link: "/organization/permissions/", icon: "fa-eye-slash", isVisible: true, permissionRequired: "view_permission" },
            { name: "Groups", link: "/organization/groups/", icon: "fa-universal-access", isVisible: true, permissionRequired: "view_group" },
            { name: "Financial Accounts", link: "/organization/financial_accounts/", icon: "fa-sliders", isVisible: true, permissionRequired: "view_financialaccount" },
            { name: "Object Permissions", link: "/organization/object_permissions/", icon: "fa-key", isVisible: true, permissionRequired: "view_groupobjectpermission" },
        ],
    },
    {
        parentName: "BOS Attributes",
        items: Object.keys(AttributeConfigurations) && Object.keys(AttributeConfigurations).sort().map(key => ({ name: AttributeConfigurations[key].title, link: `/attribute/${key}`, icon: "fa-database", isVisible: true, permissionRequired: null })),
    },
    {
        parentName: "More",
        items: [
            { name: "Report Builder", link: "/report_builder/", icon: "fa-table", isVisible: true, permissionRequired: null, external: true },
            { name: "Bulk Upload", link: "/billing/bulk_upload/", isVisible: true, permissionRequired: null, external: true },
            { name: "Toll Upload", link: "/billing/upload_tolls/", isVisible: true, permissionRequired: null, external: true },
            { name: "Tickets Upload", link: "/billing/import_tickets/", isVisible: true, permissionRequired: null, external: true },
            { name: "Automations", link: "/automations/", icon: "fa-cogs", isVisible: true, permissionRequired: "view_automation" },
            { name: "Chatbot", link: "/chatbot/", icon: "fa-cogs", isVisible: true, permissionRequired: "view_chatbot_custom" },

        ],
    }
]

const UpdateMember = gql`
    mutation updateMember($input: UpdateMemberMutationInput!){
        updateMember(input:$input){
        ok
        errors{
            messages
            field
        }
    }
}`

class SideBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showPinnedMenuItems: false,
            preferredMenuItems: [],
        }
    }

    hasItemWithPermission = (menuItem) => {
        return menuItem.items.filter(subItem => subItem.isVisible).filter(subItem => !subItem.permissionRequired || (subItem.permissionRequired && this.props.hasPermissions && this.props.hasPermissions.includes(subItem.permissionRequired))).length > 0
    }

    hasItemPinned = (menuItem) => {
        return menuItem.items.filter(subItem => subItem.isVisible).filter(subItem => !subItem.permissionRequired || (subItem.permissionRequired && this.props.hasPermissions && this.props.hasPermissions.includes(subItem.permissionRequired))).filter(subItem => this.state.preferredMenuItems && this.state.preferredMenuItems.includes(subItem.name)).length > 0
    }

    addRemovePinnedItem = (itemName) => {
        return () => {
            let preferredMenuItems = [...this.state.preferredMenuItems]
            if (preferredMenuItems.includes(itemName)) {
                preferredMenuItems = preferredMenuItems.filter(item => item !== itemName)
            } else {
                preferredMenuItems.push(itemName)
            }
            this.setState({ preferredMenuItems })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!(prevProps.currentUser && prevProps.currentUser.member) && this.props.currentUser && this.props.currentUser.member && this.props.currentUser.member.showPinnedMenuItems !== this.state.showPinnedMenuItems) {
            this.setState({ showPinnedMenuItems: this.props.currentUser.member.showPinnedMenuItems })
        }
        if (!(prevProps.currentUser && prevProps.currentUser.member) && this.props.currentUser && this.props.currentUser.member && this.props.currentUser.member.preferredMenuItems !== this.state.preferredMenuItems) {
            this.setState({ preferredMenuItems: this.props.currentUser.member.preferredMenuItems })
        }
        if (prevState.showPinnedMenuItems !== this.state.showPinnedMenuItems && this.props.currentUser && this.props.currentUser.member && this.props.currentUser.member.showPinnedMenuItems !== this.state.showPinnedMenuItems) {
            this.updateSelectedInfo(this.props.currentUser.member.id, 'showPinnedMenuItems', this.state.showPinnedMenuItems)
        }
        if (prevState.preferredMenuItems !== this.state.preferredMenuItems && this.props.currentUser && this.props.currentUser.member && this.props.currentUser.member.preferredMenuItems !== this.state.preferredMenuItems) {
            this.updateSelectedInfo(this.props.currentUser.member.id, 'preferredMenuItems', this.state.preferredMenuItems)
        }
    }

    updateSelectedInfo = (memberId, name, value) => {
        this.setState({ loading: true })
        let input = { memberId }

        if (name === 'preferredMenuItems') {
            input.preferredMenuItems = value
        } else if (name === 'showPinnedMenuItems') {
            input.showPinnedMenuItems = value
        }

        this.props.client.mutate({
            mutation: UpdateMember,
            variables: { input },
        }).then((result) => {
            this.setState({ loading: false })

            if (result.data && result.data.updateMember && result.data.updateMember.ok) {
                this.props.refetchUserQuery()
                this.setState({ loading: false, keyVersion: this.state.keyVersion + 1 })
            } else {
                this.setState({ loading: false })
            }
        })
    };

    render() {
        const pathname = window.location.pathname
        return (
            <div className={"bos-left-nav" + (this.props.showLeftNav ? " show" : " hide")}>
                <ul>
                    <li className="bos-logo-item"><img src={logo} width={"75%"} alt="BOS Logo" /></li>
                    {this.state.showPinnedMenuItems ?
                        <>
                            <Label className="pinned-item-label">Pinned Items <i className="fa fa-thumb-tack" aria-hidden="true" /></Label>
                            {MENU_ITEMS.filter(menuItem => this.hasItemPinned(menuItem)).length > 0 ? MENU_ITEMS.filter(menuItem => this.hasItemPinned(menuItem)).map((menuItem, index) =>
                                <React.Fragment key={index}>
                                    <Label>{menuItem.parentName}</Label>
                                    {menuItem.items.filter(subItem => subItem.isVisible).filter(subItem => !subItem.permissionRequired || (subItem.permissionRequired && this.props.hasPermissions && this.props.hasPermissions.includes(subItem.permissionRequired))).filter(subItem => this.state.preferredMenuItems.includes(subItem.name)).map((subItem, subIndex) => {
                                        return (
                                            <li key={subIndex} className={pathname === subItem.link ? "active" : "inactive"}>
                                                <Link to={subItem.link}>
                                                    {subItem.customIcon ? subItem.customIcon :
                                                        <i className={`fa fa-lg ${subItem.icon ? subItem.icon : "fa-info"}`} aria-hidden="true" />
                                                    }
                                                    <span className="list-item-text">{subItem.name}</span>
                                                </Link>
                                                <i className={`fa fa-thumb-tack menu-list-item-pin ${this.state.preferredMenuItems && this.state.preferredMenuItems.includes(subItem.name) && "active-pin"}`} onClick={this.addRemovePinnedItem(subItem.name)} aria-hidden="true" />
                                            </li>
                                        )
                                    })}
                                </React.Fragment>
                            ) : <div className="text-center text-danger pt-3">No Pinned Items Found!</div>}
                        </> :
                        MENU_ITEMS.filter(menuItem => this.hasItemWithPermission(menuItem)).map((menuItem, index) =>
                            <React.Fragment key={index}>
                                <Label>{menuItem.parentName}</Label>
                                {menuItem.items.filter(subItem => subItem.isVisible).filter(subItem => !subItem.permissionRequired || (subItem.permissionRequired && this.props.hasPermissions && this.props.hasPermissions.includes(subItem.permissionRequired))).map((subItem, subIndex) => {
                                    return (
                                        <li key={subIndex} className={pathname === subItem.link ? "active" : "inactive"}>
                                            <Link to={subItem.link} target={subItem.external ? "_blank" : null}>
                                                {subItem.customIcon ? subItem.customIcon :
                                                    <i className={`fa fa-lg ${subItem.icon ? subItem.icon : "fa-info-circle"}`} aria-hidden="true" />
                                                }
                                                <span className="list-item-text">{subItem.name}</span>
                                            </Link>
                                            <i className={`fa fa-thumb-tack menu-list-item-pin ${this.state.preferredMenuItems && this.state.preferredMenuItems.includes(subItem.name) && "active-pin"}`} onClick={this.addRemovePinnedItem(subItem.name)} aria-hidden="true" />
                                        </li>
                                    )
                                })}
                            </React.Fragment>
                        )
                    }
                </ul>
                <div className="bos-left-nav-footer mx-3 mt-5">
                    <a href="javascript:void(0)" onClick={() => this.setState({ showPinnedMenuItems: !this.state.showPinnedMenuItems })}><i className={this.state.showPinnedMenuItems ? "fa fa-bars" : "fa fa-thumb-tack"} aria-hidden="true" />&nbsp;&nbsp;Show {this.state.showPinnedMenuItems ? "All" : "Pinned"}</a>

                    <a href="javascript:void(0)" onClick={this.props.updateShowLeftNav}><i className={"fa float-right fa-2x fa-arrow-circle-o-left"} aria-hidden="true" /></a>
                </div>
            </div>
        )
    }
}

export default compose(
    withApollo,
    graphql(HasPermissionsQuery, {
        options: () => ({
            variables: {
                userPermissions: [
                    "view_workorder", "view_job", "view_carissues", "view_cardeal", "view_pricingtemplate",
                    "view_promotiontemplate", "view_tenant", "view_member", "view_branch",
                    "view_financialaccount", "view_permission", "view_groupobjectpermission",
                    "view_group", "view_carregistration", "view_carinspection", "view_automation",
                    "view_chatbot_custom", "view_taskrabbit",
                ]
            }, fetchPolicy: "network-only", notifyOnNetworkStatusChange: true
        }),
        props({ data: { hasPermissions, loading, variables } }) {
            return { hasPermissions, loading, variables }
        }
    }),
)(SideBar)