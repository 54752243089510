import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { withApollo } from 'react-apollo/index';
import { Container, Row, Col, Card, CardBody, Button, FormGroup, Input, Form, InputGroup, InputGroupAddon, InputGroupText, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

class Page404 extends Component {
    constructor(props){
        super(props);
    }
    
	render() {
        return (
            <Container fluid className="Page404">
                <Row>
                    <Col xs={{size:4, offset:4}} className="section-404">
                        <p>404</p>
                        <small>The page you requested could not found</small>
                        <br /><br /><a className="btn btn-primary" href="/">Go To HomePage</a>
                    </Col>
                </Row>
            </Container>
		);
	}
}

export default compose(
    withApollo,
)(Page404);
