import React, { Component } from 'react'
import { compose, graphql, withApollo } from 'react-apollo'
import './TransactionListNew.css'
import { Table, Button, Progress, Badge, UncontrolledTooltip } from 'reactstrap'
import TransactionInfo from './TransactionInfo'
import DatetimeRenderer from "../../Material/DatetimeRenderer"
import '../../Drivers/Driver/Detail/DetailsNew/DriverDetailNew.css'
import { TransactionsQuery } from "./Queries"

class TransactionListNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModal: "",
            transactionId: null,
            loading: false,
            selectAll: false
        }
    }
    openTransaction = (transactionId) => {
        this.setState({ openModal: "TransactionInfo", transactionId: transactionId })
    };

    updateOrder = (value) => {
        this.props.updateOrder(value)
    };

    updateSelected = (value) => {
        if (!this.props.setSelectedTransactions)
            return

        let selectedTransactions = this.props.selectedTransactions
        if (selectedTransactions.includes(value)) {
            selectedTransactions = selectedTransactions.filter(item => item !== value)
        } else {
            selectedTransactions.push(value)
        }
        this.updateSelectedTransactions(selectedTransactions)
    };
    toggleSelectAllClick = () => {
        let selectedTransactions = []
        if (this.props.transactions && !this.state.selectAll) {
            selectedTransactions = this.props.transactions.edges.map(n => n.node.id)
            this.setState({ selectAll: true })
        } else {
            this.setState({ selectAll: false })
        }
        this.updateSelectedTransactions(selectedTransactions)
    };
    updateSelectedTransactions = (selectedTransactions) => {
        this.props.setSelectedTransactions(selectedTransactions)
        let sum = this.props.transactions.edges.filter(transaction => selectedTransactions.includes(transaction.node.id)).reduce((currentSum, transaction) => (this.props.includeUpdates === false ? parseFloat(transaction.node.dynamicAmount) : parseFloat(transaction.node.amount)) + currentSum, 0)
        this.props.setAggregatedInfo({ ...this.props.aggregatedInfo, sum: sum })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedTransactions !== this.props.selectedTransactions && this.state.selectAll && ((this.props.selectedTransactions.length !== this.props.transactions.edges.length) || this.props.selectedTransactions.length === 0)) {
            this.setState({ selectAll: false })
        }
    }

    render() {
        const { transactions, columnConfig } = this.props
        return (
            <div className="TransactionListNew pt-3">
                {this.state.openModal === "TransactionInfo" && (
                    <TransactionInfo
                        id={this.state.transactionId}
                        refetchQuery={this.props.refetchTransactionsQuery}
                        refetchDriverQuery={this.props.refetchQuery}
                        handleClose={() => this.setState({ openModal: "", transactionId: null })}
                        open={this.state.openModal === "TransactionInfo"}
                        driverId={this.props.driverId}
                        currentUser={this.props.currentUser}
                    />
                )}
                <Table responsive>
                    <thead style={{ position: "sticky", top: -1, backgroundColor: "white" }}>
                        <tr style={{ color: "black" }}>
                            <th onClick={this.toggleSelectAllClick} className="select-all-checkbox"> {this.state.selectAll ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o" aria-hidden="true"></i>}</th>
                            {columnConfig && columnConfig.filter(col => col.visible).map((column, index) => (
                                <th key={column.id}>{column.name}</th>
                            ))}

                        </tr>
                    </thead>
                    <tbody>
                        {this.props.loading && <tr><td colSpan={12}><Progress style={{ maxHeight: "5px" }} animated color="info" value="100" /></td></tr>}
                        {transactions && transactions.edges.length ? transactions.edges.map((transaction, i) =>
                            <tr key={i + 1} className={transaction.node.amount > 0 ? "danger" : "success"}>
                                <td scope="row" className="listing-checkbox-wrapper">
                                    {(!this.props.selectedTransactions || !this.props.selectedTransactions.includes(transaction.node.id)) && <span className="listing-counter">{i + 1}</span>}
                                    <span className="listing-checkbox" onClick={() => this.updateSelected(transaction.node.id)}>
                                        {this.props.selectedTransactions && this.props.selectedTransactions.includes(transaction.node.id) ? <i className="fa fa-check-square-o" aria-hidden="true"></i> : <i className="fa fa-square-o unchecked" aria-hidden="true"></i>}
                                    </span>
                                </td>
                                {columnConfig && columnConfig.filter(column => column.visible).map(column =>
                                    <>
                                        {column.id === "id" &&
                                            <td className="no_decoration">
                                                {transaction.node.pk}
                                                {transaction.node.updateFor && <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", textDecoration: "line-through" }}>{transaction.node.updateFor.updateForPK}</small></>}
                                            </td>
                                        }
                                        {column.id === "agreementCarId" &&
                                            <td className="no_decoration">
                                                {transaction.node.agreement && transaction.node.agreement.car ? transaction.node.agreement.car.carPK : "--"}
                                                {(transaction.node.agreement && (transaction.node.agreement.startDate || transaction.node.agreement.endDate)) && <><br /><small style={{ opacity: 0.9, fontSize: "0.9rem", cursor: "pointer" }}>{transaction.node.agreement.startDate ? <DatetimeRenderer datetime={transaction.node.agreement.startDate} /> : "--"} - {transaction.node.agreement.endDate ? <DatetimeRenderer datetime={transaction.node.agreement.endDate} /> : "--"}</small></>}
                                            </td>
                                        }
                                        {column.id === "chargeType" &&
                                            <td className="shortText">
                                                <a id={"transactionEdit" + i} role="button" tabIndex="0" style={{ color: "inherit" }} onClick={() => !transaction.node.isDeleted && this.openTransaction(transaction.node.id)}>{transaction.node.chargeType ? transaction.node.chargeType.name : '--'}</a><small>{transaction.node.groupType ? " (" + transaction.node.groupType.name + ")" : ''}</small>
                                                {!this.state.openModal && <UncontrolledTooltip delay={{ "show": 400, "hide": 200 }} target={"transactionEdit" + i} placement="right" autohide={false} className="bos-custom-tooltip">
                                                    <label>{transaction.node.chargeType ? transaction.node.chargeType.name : '--'}</label>
                                                    <p className="bos-custom-tooltip-body">
                                                        <b>Amount:</b> ${transaction.node.amount}<br />
                                                        <b>Event Date:</b> <DatetimeRenderer datetime={transaction.node.eventDatetime} /> <br />
                                                        <b>Created By:</b> {transaction.node.createdBy ? transaction.node.createdBy.username : "System"}<br />
                                                        <b>Branch:</b> {transaction.node.branch ? transaction.node.branch.name : "--"}<br />
                                                    </p>
                                                </UncontrolledTooltip>}
                                                {transaction.node.notes &&
                                                    <>
                                                        <UncontrolledTooltip placement="right" target={"notes" + i}>{transaction.node.notes}</UncontrolledTooltip>
                                                        <br /><small id={"notes" + i} style={{ opacity: 0.9, fontSize: "0.9rem", cursor: "pointer" }}>{transaction.node.notes}</small>
                                                    </>
                                                }
                                            </td>}
                                        {column.id === "amount" && <td>
                                            {this.props.includeUpdates === false && parseFloat(transaction.node.amount) !== parseFloat(transaction.node.dynamicAmount) ? <>
                                                <b id={"dynamicAmount" + i}>${transaction.node.dynamicAmount}*</b>
                                                <UncontrolledTooltip placement="right" target={"dynamicAmount" + i}>${transaction.node.amount} {"->"} ${transaction.node.dynamicAmount}</UncontrolledTooltip>
                                            </> : <>${transaction.node.amount}</>}
                                            {transaction.node.status === "0" && <Badge style={{ "fontSize": "xx-small" }}>PENDING</Badge>}
                                        </td>}
                                        {column.id === "balance" && <td className="no_decoration">{transaction.node.isDeleted ? <Badge color="danger">DELETED</Badge> : <>${this.props.includeUpdates === false ? transaction.node.dynamicBalance : transaction.node.balance}</>}</td>}
                                        {column.id === "dateAdded" && <td className="no_decoration">{transaction.node.dateAdded ? <DatetimeRenderer datetime={transaction.node.dateAdded} format="llll" /> : "--"}</td>}
                                        {column.id === "eventDatetime" && <td className="no_decoration">{transaction.node.eventDatetime ? <DatetimeRenderer datetime={transaction.node.eventDatetime} format="llll" /> : "--"}</td>}
                                        {column.id === "dueDate" && <td className="no_decoration">{transaction.node.dueDate ? <DatetimeRenderer datetime={transaction.node.dueDate} format="ddd, MMM D, YYYY" /> : "--"}</td>}
                                        {column.id === "createdBy" && <td className="no_decoration">{transaction.node.createdBy ? transaction.node.createdBy.username : "System"}</td>}
                                    </>
                                )}
                            </tr>) : !this.props.loading && <tr><td colSpan={12}>No Transaction Found</td></tr>}
                        {this.props.loading && <tr><td colSpan={12}><Progress style={{ maxHeight: "5px" }} animated color="info" value="100" /></td></tr>}
                        {this.props.transactions && this.props.transactions.pageInfo.hasNextPage &&
                            <tr>
                                <td colSpan={6}><Button style={{ paddingLeft: 0 }} onClick={this.props.loadMoreEntries} color="link linkHoverEffect">Show older <i className={"fa fa-caret-right"}></i></Button></td>
                            </tr>
                        }
                    </tbody >
                </Table >
            </div >
        )
    }
}

export default compose(
    withApollo,
    graphql(TransactionsQuery, {
        options: ({ driverId, chargeTypeIds, groupTypeIds, minAmount, maxAmount, eventDatetimeGte, eventDatetimeLte, dateAddedGte, dateAddedLte, notes, includeDeleted, includeUpdates, transactionIds }) => ({
            variables: {
                driverId: driverId,
                ids: transactionIds,
                chargeTypeIds,
                groupTypeIds,
                includeDeleted,
                includeUpdates,
                eventDatetimeGte,
                eventDatetimeLte,
                dateAddedGte,
                dateAddedLte,
                minAmount: minAmount,
                maxAmount: maxAmount,
                notes: notes,
                orderBy: ["-date_added"],
                first: 30,
                loadDynamicValues: includeUpdates === false ? true : false
            }
        }),
        // This function re-runs every time `data` changes, including after `updateQuery`,
        // meaning our loadMoreEntries function will always have the right cursor
        props({ data: { loading, transactions, fetchMore, variables } }) {
            return {
                loading,
                transactions,
                variables,
                refetchTransactionsQuery: () => {
                    //No apollo function that refetches in place with pagination considered so this function instead
                    let currentLength = transactions && transactions.edges && transactions.edges.length > 30 ? transactions.edges.length : 30
                    return fetchMore({
                        query: TransactionsQuery,
                        variables: {
                            ...variables,
                            first: currentLength,
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.transactions.edges
                            const pageInfo = fetchMoreResult.transactions.pageInfo
                            return {
                                transactions: {
                                    edges: [...newEdges],
                                    pageInfo,
                                    __typename: previousResult.transactions.__typename
                                },
                            }
                        },
                    })
                },
                loadMoreEntries: () => {
                    return fetchMore({
                        query: TransactionsQuery,
                        variables: {
                            cursor: transactions.pageInfo.endCursor,
                            ...variables
                        },
                        updateQuery: (previousResult, { fetchMoreResult }) => {
                            const newEdges = fetchMoreResult.transactions.edges
                            const pageInfo = fetchMoreResult.transactions.pageInfo

                            return {
                                // Put the new comments at the end of the list and update `pageInfo`
                                // so we have the new `endCursor` and `hasNextPage` values
                                transactions: {
                                    edges: [...previousResult.transactions.edges, ...newEdges],
                                    pageInfo,
                                    __typename: previousResult.transactions.__typename
                                },
                            }
                        },
                    })
                },
            }
        },
    }),
)(TransactionListNew)
